import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { HeroImage, Intro, Category, Pagination, Section } from "@components/Work"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Arrow } from "@components/UI"
import { splitText } from "@utils"
import workData from "@json/work.json"

const MarcosCSRPage = ({ data }) => {
  const { logoOne, logoTwo, logoThree, printOne, printTwo, printThree, printFour } = data
  const { name, headline, theme } = workData["marcoscsr"]
  const { themeColor } = usePageSetup({
    themeColor: theme.background,
    navigationColor: theme.navigationColor,
    displayGeneralForm: false,
  })

  return (
    <Layout>
      <Seo title={name} description={headline} />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1 className="tracking-normal text-white text-18">{name}</h1>
            <h2
              data-aos="stagger"
              className="text-work-headline mt-clamp-6-8"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": headline,
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-white" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

      <main className="overflow-hidden">
        <HeroImage client="marcoscsr" />
        <Category>ESG, CSR, and DEI Branding</Category>
        <Intro>
          <Intro.Overview>
            <Intro.Title>Satisfying Marco’s hunger for an irresistible CSR brand.</Intro.Title>
            <div className="mt-10">
              <p>
                Marco’s Pizza had all the ingredients for an inspiring social good story but needed a big idea to bring
                it all together and a communications toolkit to ensure flawless execution—and that’s exactly what JK
                served up.
              </p>
              <p>
                We worked hand-in-hand with key stakeholders to create a meaningful and engaging CSR identity for
                Marco’s, one that telegraphs their beliefs, aligns with their values, and dovetails seamlessly with
                their corporate brand. And we gave them a social good branding toolkit that allows anyone in the company
                to share their CSR story in a consistently compelling way across all audiences and touchpoints.
              </p>
            </div>
          </Intro.Overview>
          <Intro.Deliverables>
            <li>Branding</li>
            <li>Messaging</li>
            <li>Naming</li>
            <li>Logo development</li>
            <li>Visual identity</li>
            <li>Brand architecture</li>
            <li>Art and graphic design</li>
            <li>Toolkit development</li>
          </Intro.Deliverables>
        </Intro>

        <section className="mt-clamp-34-29">
          <div className="container relative flex">
            <CursorMarker>
              <CursorMarker.Gallery />
            </CursorMarker>
            <div className="w-full sm:w-5/6 lg:w-1/2">
              <Carousel>
                <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                  <Carousel.Slide>
                    <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                      <GatsbyImage image={getImage(logoOne)} alt="Logo 1" />
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                      <GatsbyImage image={getImage(logoTwo)} alt="Logo 2" />
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                      <GatsbyImage image={getImage(logoThree)} alt="Logo 3" />
                    </div>
                  </Carousel.Slide>
                </Carousel.Swiper>
              </Carousel>
            </div>
          </div>
        </section>

        <section className="pt-24">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Title>A process that delivers, hot and fresh.</Section.Title>
            <Section.Description>
              <p>
                Our social good storytelling process started with an identity workshop, where we collaborated with the
                Marco’s team to identify the essential components of their CSR identity. We then used these building
                blocks to create “Rising Every Day,” a CSR brand that reflects the company’s beliefs, inspires their
                patrons to participate, and serves as a rallying cry and point of pride for their employees. It also
                helped their hundreds of franchisees easily understand the value of Marco’s CSR initiatives and engage
                with the company’s programs.
              </p>
            </Section.Description>
          </Section>
        </section>

        <section>
          <div className="mt-24 bg-work-marcos-darkGray pb-clamp-7-16">
            <div className="container relative">
              <div className="absolute inset-0">
                <div className="w-full aspect-w-326 aspect-h-93 sm:aspect-w-1436 sm:aspect-h-189 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div>
                <div className="overflow-hidden shadow">
                  <GatsbyImage
                    className="w-full h-full"
                    objectFit="contain"
                    image={getImage(printOne)}
                    alt="Print - 1"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-50">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Title>Always rising.</Section.Title>
            <Section.Description>
              <p>
                Our partnership with Marco’s Pizza set them up for long-term success—delivering a powerful and genuine
                CSR brand, opportunities for increased awareness and participation, and a framework that allows for
                continuous growth and evolution.
              </p>
              <p>
                We’re excited to continue helping Marco’s make a difference across the country and in the communities
                they serve. Together, we’ll keep nurturing the “Rising Every Day” brand and amplifying the positive
                change they bring about—one slice, one community, and one heartfelt act of goodness at a time.
              </p>
            </Section.Description>
          </Section>
        </section>
        <section className="pb-clamp-36-69">
          <div className="relative bg-work-marcos-darkGreen extend before:bg-work-marcos-darkGreen after:bg-work-marcos-darkGreen mt-28">
            <div className="absolute inset-0 z-1">
              <div className="w-full aspect-w-1436 aspect-h-93 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
            </div>
            <div className="absolute inset-x-0 bottom-0 lg:hidden z-1">
              <div className="w-full aspect-w-1436 aspect-h-93 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
            </div>
            <div className="container relative z-10">
              <div className="flex flex-wrap grid-cols-12 grid-rows-1 gap-4 lg:grid">
                <div className="w-full col-start-1 col-end-9 row-start-1 row-end-1">
                  <div className="overflow-hidden">
                    <GatsbyImage
                      className="w-full h-full"
                      objectFit="contain"
                      image={getImage(printTwo)}
                      alt="Print - 2"
                    />
                  </div>
                </div>
                <div className="w-full col-start-1 col-end-7 row-start-1 row-end-1 sm:flex-1 lg:flex lg:pl-10 lg:pr-3 lg:pb-7">
                  <div className="self-end overflow-hidden">
                    <GatsbyImage
                      className="w-full h-full"
                      objectFit="contain"
                      image={getImage(printThree)}
                      alt="Print - 3"
                    />
                  </div>
                </div>
                <div className="w-full col-start-7 col-end-13 row-start-1 row-end-1 sm:flex-1">
                  <div className="overflow-hidden lg:translate-y-16 lg:transform">
                    <div className="hidden w-full lg:block aspect-w-710 aspect-h-211"></div>
                    <GatsbyImage
                      className="w-full h-full"
                      objectFit="contain"
                      image={getImage(printFour)}
                      alt="Print - 4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <Pagination client="marcoscsr" />
      </main>
    </Layout>
  )
}

export default MarcosCSRPage

export const marcosCSRQuery = graphql`
  query MarcosCSRPageQuery {
    logoOne: file(relativePath: { eq: "work/marcos-csr/logo-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    logoTwo: file(relativePath: { eq: "work/marcos-csr/logo-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    logoThree: file(relativePath: { eq: "work/marcos-csr/logo-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOne: file(relativePath: { eq: "work/marcos-csr/print-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwo: file(relativePath: { eq: "work/marcos-csr/print-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThree: file(relativePath: { eq: "work/marcos-csr/print-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printFour: file(relativePath: { eq: "work/marcos-csr/print-04.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
